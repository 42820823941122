import React from "react";
import s from "./AdminPage.module.css";
import { NavLink } from "react-router-dom";
const AdminPage = () => {
  return (
    <div className={s.menu_div}>
      <NavLink
        to="/admin/print-docs"
        className={(navData) => (navData.isActive ? s.active : s.item)}
      >
        Печать документов
      </NavLink>
      <NavLink
        to="/admin/events"
        className={(navData) => (navData.isActive ? s.active : s.item)}
      >
        Cобытия
      </NavLink>
    </div>
  );
};
export default AdminPage;
