import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchUserRoles = createAsyncThunk('auth/fetchUserRoles', async (_, { rejectWithValue }) => {
  try {
    const response = await fetch('http://localhost:80/roles', {
      method: 'GET',
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Не удалось получить роли пользователя');
    }

    return await response.json();
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: JSON.parse(localStorage.getItem('isAuthenticated')) || false,
    user: JSON.parse(localStorage.getItem('user')) || null,
    roles: JSON.parse(localStorage.getItem('roles')) || [],  // Загружаем роли из localStorage
    permissions: [],
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user || state.user;
      state.roles = action.payload.roles || state.roles;
      state.permissions = action.payload.permissions || state.permissions;
      state.error = null;

      localStorage.setItem('isAuthenticated', JSON.stringify(true));
      localStorage.setItem('user', JSON.stringify(state.user));
      localStorage.setItem('roles', JSON.stringify(state.roles));  // Сохраняем роли в localStorage
    },

    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.roles = [];
      state.permissions = [];
      state.error = null;

      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('user');
      localStorage.removeItem('roles'); // Удаляем роли из localStorage при выходе
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchUserRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
      localStorage.setItem('roles', JSON.stringify(state.roles));  // Обновляем роли в localStorage при успешном запросе
    })
    .addCase(fetchUserRoles.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const { setUser, logout, setError } = authSlice.actions;
export default authSlice.reducer;
