import React from "react";
import AddUserEvent from "./AddUserEvent";
import UserEvent from "./UserEvent";
const UserEvents = (props) => {
  const findEventName = (eventId) => {
    let foundObject = props.events.find((obj) => obj.id === eventId);
    let eventName;
    if (foundObject) {
      eventName = foundObject.eventName;
    }
    return eventName;
  };
  //где-то здесь нужно получить сперва юзерИвенты
  let userEvents = props.userEvents.map((e) => (
    <UserEvent
      eventName={findEventName(e.eventId)}
      eventDate={e.eventDate}
      id={props.id}
    />
  ));
  return (
    <>
      {userEvents}
      <AddUserEvent
        userId={props.id}
        events={props.events}
        AddUserEvent={props.addUserEvent}
      />
    </>
  );
};
export default UserEvents;
