import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SuccessRegistration.css";

const SuccessRegistration = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/login");
    }, 5000); // Перенаправление через 5 секунд
    return () => clearTimeout(timeout); // Очистка таймера при размонтировании
  }, [navigate]);

  return (
      <div className="success-container">
        <h1 className="success-header">Регистрация прошла успешно!</h1>
        <p className="success-message">
          Теперь Вы можете выполнить вход. Вы будете перенаправлены на страницу
          логина через 5 секунд.
        </p>
      </div>
  );
};

export default SuccessRegistration;
