import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { setUser, setError } from '../../redux/authSlice';

const LoginPage = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.auth.error);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("logout") === "true") {
      dispatch(setError("Вы успешно вышли из системы."));
    }
  }, [location, dispatch]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await fetch('/api/perform_login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include',
      });

      if (!response.ok) {
        const errorContent = await response.json();
        dispatch(setError(errorContent.message || 'Ошибка входа'));
        return;
      }

      const userData = await response.json();

      // Устанавливаем пользователя и его роли сразу при успешном входе
      dispatch(
          setUser({
            user: userData.user,
            roles: userData.roles || [], // Роли получаем сразу
            permissions: userData.permissions || [],
          })
      );

      navigate('/profile'); // Переход на главную страницу
    } catch (error) {
      dispatch(setError('Ошибка сети. Попробуйте еще раз.'));
    } finally {
      setLoading(false);
    }
  };

  return (
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card shadow-lg">
              <div className="card-body">
                <h2 className="card-title text-center mb-4">Вход</h2>

                {error && <div className="alert alert-danger">{error}</div>}

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-floating mb-3">
                    <input
                        type="email"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        id="email"
                        placeholder="name@example.ru"
                        {...register("email", { required: "E-mail обязателен" })}
                    />
                    <label htmlFor="email">E-mail</label>
                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                  </div>

                  <div className="form-floating mb-3">
                    <input
                        type={visible ? "text" : "password"}
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                        id="password"
                        placeholder="Пароль"
                        {...register("password", { required: "Пароль обязателен" })}
                    />
                    <label htmlFor="password">Пароль</label>
                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                  </div>

                  <div className="form-check mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="showPassword"
                        onClick={() => setVisible(!visible)}
                    />
                    <label className="form-check-label" htmlFor="showPassword">
                      Показать пароль
                    </label>
                  </div>

                  <div className="form-check mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="remember-me"
                        {...register("remember-me")}
                    />
                    <label className="form-check-label" htmlFor="remember-me">
                      Запомнить меня
                    </label>
                  </div>

                  <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                    {loading ? "Вход..." : "Войти"}
                  </button>
                </form>

                <p className="text-center mt-3">
                  Ещё не зарегистрированы? <a href="/registration">Зарегистрироваться</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default LoginPage;
