import React, { useState } from "react";
import { useForm } from "react-hook-form";
import UserEvents from "./UserEvents";
const GetUserEvents = (props) => {
  const { register, handleSubmit } = useForm();
  const [userEventsVisible, setUserEventsVisible] = useState(false);
  const getUserEvents = async (pioneerId) => {
    try {
      const rawResponse = await fetch(
        `http://localhost:8080/api/user-events/${pioneerId}`,
        {
          method: "GET",
        }
      );
      const data1 = await rawResponse.json();
      props.setUserEvents(data1);
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmit = (data) => {
    getUserEvents(data.pioneerId);
    setUserEventsVisible(true);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="pioneerId"
            placeholder="Получить список событий по Pioneer ID"
            {...register("pioneerId", { required: true })}
          />
          <label htmlFor="pioneerId">
            Получить список событий по Pioneer ID
          </label>
        </div>
        <input type="submit" />
      </form>
      {console.log(props.userEvents)}
      {userEventsVisible && (
        <UserEvents
          id={props.pioneerId}
          events={props.events}
          userEvents={props.userEvents}
          addUserEvent={props.addUserEvent}
        />
      )}
    </>
  );
};
export default GetUserEvents;
