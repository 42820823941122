import React, { useState, useEffect } from "react";
import s from "./Menu.module.css";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/authSlice";
import {
  FaHome,
  FaInfoCircle,
  FaUserShield,
  FaFileAlt,
  FaBuilding,
  FaBullseye,
  FaPhoneAlt,
  FaUsersCog,
  FaFileDownload
} from "react-icons/fa";

const Menu = () => {
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();  // Используем хук useLocation для отслеживания текущего маршрута
  const { isAuthenticated, roles } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    roles: state.auth.roles || [],
  }));

  const handleLogout = async () => {
    try {
      const response = await fetch("/api/logout", {
        method: "POST",
        credentials: "include",
      });

      if (response.ok) {
        dispatch(logout());
        navigate("/login");
      } else {
        console.error("Error during logout");
      }
    } catch (error) {
      console.error("Network error during logout attempt:", error);
    }
  };

  const toggleMegaMenu = () => {
    setIsMegaMenuOpen((prevState) => !prevState);
  };

  const isAdmin = roles.includes("ROLE_ADMIN");

  // Закрываем меню при изменении маршрута
  useEffect(() => {
    setIsMegaMenuOpen(false);  // Скрываем меню "О нас" при изменении маршрута
  }, [location]);  // Зависимость от location, то есть при изменении маршрута меню будет сворачиваться

  return (
      <div className={s.menu_div}>
        {/* Левый блок меню */}
        <div className={s.menu_links}>
          <NavLink to="/" className={s.menu_item}>
            <FaHome /> Главная  {/* Иконка для "Главная" */}
          </NavLink>

          {/* Кнопка "О нас" с новой иконкой */}
          <div
              className={`${s.menu_item}`}
              onClick={toggleMegaMenu}  // Открытие/закрытие меню по клику
          >
            <FaInfoCircle /> О нас  {/* Иконка для "О нас" */}
          </div>

          {/* Выпадающее мегаменю */}
          <div className={`${s.megamenu} ${isMegaMenuOpen ? s.active : ""}`}>
            <NavLink to="/documents" className={s.megamenu_item}>
              <FaFileDownload /> Документы {/* Новая ссылка для документов */}
            </NavLink>
            <NavLink to="/about/organization" className={s.megamenu_item}>
              <FaBuilding /> Об организации  {/* Иконка для "Об организации" */}
            </NavLink>
            <NavLink to="/about/mission" className={s.megamenu_item}>
              <FaBullseye /> Миссия и цели  {/* Иконка для "Миссия и цели" */}
            </NavLink>
            <NavLink to="/about/contacts" className={s.megamenu_item}>
              <FaPhoneAlt /> Контакты  {/* Иконка для "Контакты" */}
            </NavLink>
            <NavLink to="/about/team" className={s.megamenu_item}>
              <FaUsersCog /> Наша команда  {/* Иконка для "Наша команда" */}
            </NavLink>
          </div>
        </div>

        {/* Блок аутентификации */}
        <div className={s.auth_section}>
          {isAuthenticated ? (
              <>
                <NavLink to="#" onClick={handleLogout} className={s.auth_button}>
                  Выйти {/* С иконкой не робит */}
                </NavLink>

                {isAdmin && (
                    <NavLink to="/admin" className={s.auth_button}>
                      <FaUserShield /> Админка {/* Иконка для "Админка" */}
                    </NavLink>
                )}
              </>
          ) : (
              <NavLink to="/login" className={s.auth_button}>
                Войти  {/* Убираем иконку с кнопки "Войти" */}
              </NavLink>
          )}
        </div>
      </div>
  );
};

export default Menu;
