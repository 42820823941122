import React, { useEffect } from "react";
import AddEvent from "./AddEvent";
import GetUserEvents from "./GetUserEvents";
import Event from "./Event";
import AdminPage from "../AdminPage";
const AllEvents = (props) => {
  const getEvents = async () => {
    try {
      const rawResponse = await fetch(`http://localhost:8080/api/events`, {
        method: "GET",
      });
      const data = await rawResponse.json();
      props.setEvents(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEvents();
  }, []);
  let events = props.events.map((item) => (
    <Event eventName={item.eventName} description={item.description} />
  ));
  return (
    <>
      <AdminPage />
      {events}
      <AddEvent addEvent={props.addEvent} />
      <br />
      <GetUserEvents
        pioneerId={props.pioneerId}
        events={props.events}
        userEvents={props.userEvents}
        setUserEvents={props.setUserEvents}
        setPioneerId={props.setPioneerId}
        addUserEvent={props.addUserEvent}
      />
    </>
  );
};
export default AllEvents;
