import React from "react";
import { connect } from "react-redux";
import { setNews } from "../../../redux/NewsPreviewReducer";
import NewsPreview from "./NewsPreview";

const mapStateToProps = (state) => {
  return {
    news: state.NewsPreview.news,
  };
};
const NewsPreviewContainer = connect(mapStateToProps, { setNews })(NewsPreview);

export default NewsPreviewContainer;
