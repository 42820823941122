const SET_NEWS = "SET_NEWS";
let initialState = {
  news: [
    {
      source: "https://vk.com/kpo43?w=wall-121701872_162",
      name: "шпагинцы в городе - герое Волгограде",
      img: "https://sun9-1.userapi.com/impg/QCDTuG3CP4EOI04faOPwnjD7mMa31hEx7jdy5w/2S7hpc-j5Dg.jpg?size=1280x960&quality=95&sign=5981cd51f009a87556f606cd3f4b970e&type=album",
      description:
        "Пионеры Красной Поляны посетили памятные места и приняли в свои ряды новых пионеров.",
    },
    {
      source: "https://vk.com/public222769478?w=wall-222769478_156",
      name: "Кировские пионеры на Красной площади",
      img: "https://sun9-4.userapi.com/impg/ApcTE3ZdrdEch-lEuY5U0xR4FmUWiWnrciDxxQ/ETYyCxLyunU.jpg?size=1280x853&quality=95&sign=e53c26c6f77d2fd1864cd9ae060438f4&type=album",
      description:
        "Пионеры из Зуевского и Афанасьевского районов приняли участие в праздновании Дня Пионерии в Москве.",
    },
    {
      source: "https://vk.com/kpo43?w=wall-121701872_137",
      name: "шпагинцы делают добрые дела по заветам тимуровцев!",
      img: "https://sun9-58.userapi.com/impg/3Y1SzyXtlzPMlFzib0SKFU5hzj8Xytk9nmSzZw/gTklU7MhUpM.jpg?size=1280x960&quality=95&sign=aa84a2f5fdbfba72e3e56210b6bd3a6e&type=album",
      description: "Пионеры помогли детям войны с уборкой осенней листвы",
    },
  ],
};
const NewsPreviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS: {
      let stateCopy = { ...state };
      stateCopy.news = { ...action.news };
      return stateCopy;
    }
    default:
      return state;
  }
};
export const setNews = (news) => ({ type: SET_NEWS, news });
export default NewsPreviewReducer;
