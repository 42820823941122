import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, requiredRole }) => {
  const { isAuthenticated, roles } = useSelector((state) => state.auth);

  return isAuthenticated && roles.includes(requiredRole) ? (
      children
  ) : (
      <Navigate to="/login" />
  );
};

export default PrivateRoute;
