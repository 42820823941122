import React from "react";
import Slider from "react-slick";
import NewsPreviewElement from "./NewsPreviewElement";
import s from "./NewsPreview.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NewsPreview = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
      <div className={s.newsContainer}>
        <h1>Наши новости</h1>
        <Slider {...settings} className={s.slider}>
          {props.news.map((item, index) => (
              <NewsPreviewElement
                  key={index}
                  source={item.source}
                  img={item.img}
                  name={item.name}
                  description={item.description}
              />
          ))}
        </Slider>
      </div>
  );
};

export default NewsPreview;
