import React from "react";
import { FaDownload } from "react-icons/fa"; // Иконка для скачивания
import s from "./DocumentsPage.module.css"; // Импортируем CSS файл

const DocumentsPage = () => {
  return (
      <div className={s.container}>
        <h1 className={s.title}>Документы Кировской пионерии</h1>
        <p className={s.subtitle}>Вы можете скачать следующие документы:</p>
        <div className={s.documentsList}>
          <ul className={s.list}>
            <li className={s.listItem}>
              <a href="/documents/Устав.pdf" download className={s.link}>
                <FaDownload className={s.icon} />
                <span className={s.text}>Устав Кировской пионерии</span>
              </a>
            </li>
            <li className={s.listItem}>
              <a href="/documents/Свидетельство%20о%20регистрации%20КПО.pdf" download className={s.link}>
                <FaDownload className={s.icon} />
                <span className={s.text}>Свидетельство о регистрации Кировской пионерии</span>
              </a>
            </li>
            <li className={s.listItem}>
              <a href="/documents/Движение%20Первых%20-%20КПО.pdf" download className={s.link}>
                <FaDownload className={s.icon} />
                <span className={s.text}>Приём КПО в коллективные участники Движения Первых</span>
              </a>
            </li>
            <li className={s.listItem}>
              <a href="/documents/Выписка%20из%20реестра.pdf" download className={s.link}>
                <FaDownload className={s.icon} />
                <span className={s.text}>Выписка из реестра лицензий</span>
              </a>
            </li>
            <li className={s.listItem}>
              <a href="/documents/Уведомление%20о%20предоставлении%20лицензии.pdf" download className={s.link}>
                <FaDownload className={s.icon} />
                <span className={s.text}>Лицензия на осуществление образовательной деятельности</span>
              </a>
            </li>
            <li className={s.listItem}>
              <a href="/documents/Выписка%20из%20перечня.jpg" download className={s.link}>
                <FaDownload className={s.icon} />
                <span className={s.text}>Выписка из перечня молодежных и детских общественных объединений</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
  );
};

export default DocumentsPage;
