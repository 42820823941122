import { combineReducers, configureStore } from "@reduxjs/toolkit";
import NewsPreviewReducer from "./NewsPreviewReducer";
import EventsInterfaceReducer from "./EventsInterfaceReducer";
import authReducer from "./authSlice";

const rootReducer = combineReducers({
  NewsPreview: NewsPreviewReducer,
  Events: EventsInterfaceReducer,
  auth: authReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
