// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-area{
  margin:0 auto;
  /* @media(min-width: 731px){
    max-width: 60%;
  }
  @media (max-width: 730px){
  max-width: 100%;
} */
max-width: 100%;
}
input
{
  margin:1em;
  display: block;
  font-size: 1rem;
}
input::placeholder {
  color: #212529;
  opacity: 0.4;
}
input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #bdbdbd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.25);
}
.slider-container{
  margin:0 auto;

}
@media (max-width: 730px){
  .slider-container{
    width:100%;
    margin:0 auto;
    justify-content: center;
    padding: 2rem;
  }
}
@media (min-width: 731px){
  slider-container{
    width:100%;
  }
}
.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb;;;;;GAKC;AACH,eAAe;AACf;AACA;;EAEE,UAAU;EACV,cAAc;EACd,eAAe;AACjB;AACA;EACE,cAAc;EACd,YAAY;AACd;AACA;EACE,cAAc;EACd,sBAAsB;EACtB,qBAAqB;EACrB,UAAU;EACV,kDAAkD;AACpD;AACA;EACE,aAAa;;AAEf;AACA;EACE;IACE,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,aAAa;EACf;AACF;AACA;EACE;IACE,UAAU;EACZ;AACF;AACA;EACE,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,oBAAoB;EACpB,UAAU;AACZ","sourcesContent":[".content-area{\r\n  margin:0 auto;\r\n  /* @media(min-width: 731px){\r\n    max-width: 60%;\r\n  }\r\n  @media (max-width: 730px){\r\n  max-width: 100%;\r\n} */\r\nmax-width: 100%;\r\n}\r\ninput\r\n{\r\n  margin:1em;\r\n  display: block;\r\n  font-size: 1rem;\r\n}\r\ninput::placeholder {\r\n  color: #212529;\r\n  opacity: 0.4;\r\n}\r\ninput:focus {\r\n  color: #212529;\r\n  background-color: #fff;\r\n  border-color: #bdbdbd;\r\n  outline: 0;\r\n  box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.25);\r\n}\r\n.slider-container{\r\n  margin:0 auto;\r\n\r\n}\r\n@media (max-width: 730px){\r\n  .slider-container{\r\n    width:100%;\r\n    margin:0 auto;\r\n    justify-content: center;\r\n    padding: 2rem;\r\n  }\r\n}\r\n@media (min-width: 731px){\r\n  slider-container{\r\n    width:100%;\r\n  }\r\n}\r\n.slick-prev {\r\n  left: 3% !important;\r\n  z-index: 1;\r\n}\r\n.slick-next {\r\n  right: 3% !important;\r\n  z-index: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
