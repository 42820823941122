import React from "react";
import s from "./NewsPreview.module.css";
const NewsPreviewElement = (props) => {
  return (
    <a className={s.newsPreviewA} href={props.source} target="_blank">
      <div className={s.card}>
        <div className={s.imgArea}>
          <img src={props.img} />
          <div class={s.overlay}>
            <p>Продолжить чтение в соцсети "Вконтакте"</p>
          </div>
        </div>

        <div className={s.info}>
          <h3>{props.name}</h3>
          <p>{props.description}</p>
        </div>
      </div>
    </a>
  );
};
export default NewsPreviewElement;
