const SET_EVENTS = "SET_EVENTS";
const ADD_EVENT = "ADD_EVENT";
const ADD_USER_EVENT = "ADD_USER_EVENT";
const SET_USER_EVENTS = "SET_USER_EVENTS";
const DELETE_USER_EVENT = "DELETE_USER_EVENT";
let initialState = {
  events: [],
  userEvents: [],
};
const EventsInterfaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENTS: {
      let stateCopy = { ...state };
      stateCopy.events = [...action.events];
      return stateCopy;
    }
    case SET_USER_EVENTS: {
      let stateCopy = { ...state };
      stateCopy.userEvents = [...action.userEvents];
      return stateCopy;
    }
    case ADD_USER_EVENT: {
      let stateCopy = { ...state };
      stateCopy.userEvents = [...state.userEvents];
      stateCopy.userEvents.push(action.userEvent);
      return stateCopy;
    }
    case ADD_EVENT: {
      let stateCopy = { ...state };
      stateCopy.events = [...state.events];
      stateCopy.events.push(action.event);
      return stateCopy;
    }
    case DELETE_USER_EVENT: {
      let stateCopy = { ...state };
      stateCopy.userEvents = [...state.userEvents];
      stateCopy.userEvents = state.userEvents.filter(
        (item) => item.id !== action.userEvent
      );
      return stateCopy;
    }
    default:
      return state;
  }
};
export const setEvents = (events) => ({ type: SET_EVENTS, events });
export const setUserEvents = (userEvents) => ({
  type: SET_USER_EVENTS,
  userEvents,
});
export const deleteUserEvent = (userEvent) => ({
  type: DELETE_USER_EVENT,
  userEvent,
});
export const addEvent = (event) => ({ type: ADD_EVENT, event });
export const addUserEvent = (userEvent) => ({
  type: ADD_USER_EVENT,
  userEvent,
});
export default EventsInterfaceReducer;
