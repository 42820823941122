// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-gallery-container {
  padding: 20px;
  background-color: white;
  color: black;
  text-align: center;
  font-family: "Ysabeau SC", sans-serif;
}

.gallery-slider {
  margin-bottom: 20px;
}

.gallery-slide {
  padding: 10px;
}

.gallery-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.footer-info {
  margin-top: 20px;
}

.footer-info p {
  margin: 10px 0;
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.footer-logo-img {
  width: 100px; /* Размер изображения */
  height: auto;
  transition: transform 0.3s ease, filter 0.3s ease; /* Эффект увеличения и подсветки */
}

.footer-logo-img:hover {
  transform: scale(1.1); /* Увеличение изображения при наведении */
  filter: brightness(1.2); /* Подсветка изображения */
}
`, "",{"version":3,"sources":["webpack://./src/components/mainPage/FooterGallery/FooterGallery.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,YAAY,EAAE,uBAAuB;EACrC,YAAY;EACZ,iDAAiD,EAAE,kCAAkC;AACvF;;AAEA;EACE,qBAAqB,EAAE,yCAAyC;EAChE,uBAAuB,EAAE,0BAA0B;AACrD","sourcesContent":[".footer-gallery-container {\r\n  padding: 20px;\r\n  background-color: white;\r\n  color: black;\r\n  text-align: center;\r\n  font-family: \"Ysabeau SC\", sans-serif;\r\n}\r\n\r\n.gallery-slider {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.gallery-slide {\r\n  padding: 10px;\r\n}\r\n\r\n.gallery-image {\r\n  width: 100%;\r\n  border-radius: 10px;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n.footer-info {\r\n  margin-top: 20px;\r\n}\r\n\r\n.footer-info p {\r\n  margin: 10px 0;\r\n}\r\n\r\n.footer-logo {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin-top: 15px;\r\n}\r\n\r\n.footer-logo-img {\r\n  width: 100px; /* Размер изображения */\r\n  height: auto;\r\n  transition: transform 0.3s ease, filter 0.3s ease; /* Эффект увеличения и подсветки */\r\n}\r\n\r\n.footer-logo-img:hover {\r\n  transform: scale(1.1); /* Увеличение изображения при наведении */\r\n  filter: brightness(1.2); /* Подсветка изображения */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
