import React from "react";
import { FaSeedling, FaUsers, FaCheckCircle } from "react-icons/fa"; // Импортируем иконки
import s from "./MissionSection.module.css";

const MissionSection = () => {
  return (
      <div className={s.missionContainer}>
        <h2 className={s.title}>Наша миссия и цели</h2>
        <p className={s.intro}>
          Кировская пионерия – это сообщество детей и подростков, объединенных
          идеями дружбы, равенства и коллективной ответственности. Мы верим, что
          каждый ребенок может стать частью сильного общества, в котором ценятся
          труд, взаимовыручка и стремление к знаниям.
        </p>
        <div className={s.goals}>
          <div className={s.goal}>
            <h3 className={s.goalTitle}>Солидарность</h3>
            <p className={s.goalText}>
              Мы учим детей быть частью команды, уважать друг друга и строить
              отношения на основе равенства, дружбы и поддержки.
            </p>
            <FaUsers className={s.icon}/> {/* Иконка "Солидарность" */}
          </div>
          <div className={s.goal}>
            <h3 className={s.goalTitle}>Развитие</h3>
            <p className={s.goalText}>
              Мы поддерживаем стремление каждого ребенка к личностному и
              профессиональному росту. Наша организация предоставляет
              возможности
              для учебы, творчества и спорта.
            </p>
            <FaSeedling className={s.icon}/> {/* Иконка "Развитие" */}
          </div>
          <div className={s.goal}>
            <h3 className={s.goalTitle}>Ответственность</h3>
            <p className={s.goalText}>
              Мы воспитываем у детей чувство ответственности за свои действия,
              за других людей и за мир вокруг нас.
            </p>
            <FaCheckCircle className={s.icon}/> {/* Иконка "Ответственность" */}
          </div>
        </div>
      </div>
  );
};

export default MissionSection;
