import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Функция для безопасного получения данных из localStorage
const getLocalStorageData = (key, defaultValue) => {
  const item = localStorage.getItem(key);
  try {
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    console.error(`Ошибка при разборе данных из localStorage для ключа "${key}":`, error);
    return defaultValue;
  }
};

export const fetchUserRoles = createAsyncThunk('auth/fetchUserRoles', async (_, { rejectWithValue }) => {
  try {
    const response = await fetch('/roles', {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Не удалось получить роли пользователя');
    }
    return await response.json();
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: getLocalStorageData('isAuthenticated', false),
    email: getLocalStorageData('email', null),
    user: getLocalStorageData('user', null),
    roles: getLocalStorageData('roles', []), // Загружаем роли из localStorage
    permissions: [],
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.isAuthenticated = true;
      state.email = action.payload.email;
      state.user = action.payload.user || state.user;
      state.roles = action.payload.roles || state.roles;
      state.permissions = action.payload.permissions || state.permissions;
      state.error = null;
      localStorage.setItem('isAuthenticated', JSON.stringify(true));
      localStorage.setItem('email', JSON.stringify(state.email));
      localStorage.setItem('user', JSON.stringify(state.user));
      localStorage.setItem('roles', JSON.stringify(state.roles)); // Сохраняем роли в localStorage
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.email = null;
      state.user = null;
      state.roles = [];
      state.permissions = [];
      state.error = null;
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('email');
      localStorage.removeItem('user');
      localStorage.removeItem('roles'); // Удаляем роли из localStorage при выходе
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchUserRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
      localStorage.setItem('roles', JSON.stringify(state.roles)); // Обновляем роли в localStorage при успешном запросе
    })
    .addCase(fetchUserRoles.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const { setUser, logout, setError } = authSlice.actions;
export default authSlice.reducer;