import React from "react";
import AllEvents from "./AllEvents";
import { connect } from "react-redux";
import {
  deleteUserEvent,
  addUserEvent,
  addEvent,
  setEvents,
  setUserEvents,
} from "../../../redux/EventsInterfaceReducer";
const mapStateToProps = (state) => {
  return {
    events: state.Events.events,
    userEvents: state.Events.userEvents,
  };
};
const EventsContainer = connect(mapStateToProps, {
  deleteUserEvent,
  addUserEvent,
  addEvent,
  setEvents,
  setUserEvents,
})(AllEvents);
export default EventsContainer;
