// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f4f4f4;
  padding: 2rem;
}

.success-header {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 1rem;
}

.success-message {
  font-size: 1.2rem;
  color: #555555;
  max-width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Registration/SuccessRegistration.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".success-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100vh;\r\n  text-align: center;\r\n  background-color: #f4f4f4;\r\n  padding: 2rem;\r\n}\r\n\r\n.success-header {\r\n  font-size: 2.5rem;\r\n  color: #007bff;\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.success-message {\r\n  font-size: 1.2rem;\r\n  color: #555555;\r\n  max-width: 600px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
