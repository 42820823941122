import React from "react";
import s from "./Header.module.css";
import data from "../../../data/Header.json";

const Header = () => {
  // Разделяем заголовок на две строки
  const titleParts = data.name.split("в "); // Разделяем текст после "в"

  return (
      <div className={s.headerContainer}>
        <h1 className={s.headerH}>
          {titleParts[0]}в <br /> {titleParts[1]}
        </h1>
        <img
            className={s.headerImg}
            src="/images/headerImg.png"
            alt="Кировская пионерия"
        />
        <p className={s.headerDescription}>{data.description}</p>
      </div>
  );
};

export default Header;
