import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux"; // Для получения ролей пользователя
import s from "./Library.module.css";
import { useNavigate } from "react-router-dom"; // Для навигации

const Library = () => {
  const [books, setBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Для хранения сообщения об ошибке
  const roles = useSelector((state) => state.auth.roles); // Получаем роли пользователя
  const navigate = useNavigate();

  useEffect(() => {
    fetchBooks();
  }, [currentPage, searchQuery]);

  const fetchBooks = async () => {
    setLoading(true);
    const endpoint = searchQuery
        ? `/api/books/search?search=${searchQuery}&page=${currentPage}&size=5`
        : `/api/books?page=${currentPage}&size=5`;

    try {
      const response = await fetch(endpoint);
      const data = await response.json();
      setBooks(data.content || []);
      setTotalPages(data.page?.totalPages || 0);
    } catch (error) {
      console.error("Error fetching books:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBookDetails = async (id) => {
    setLoadingDetails(true);
    try {
      const response = await fetch(`/api/books/${id}`);
      const data = await response.json();
      setSelectedBook(data);
    } catch (error) {
      console.error("Error fetching book details:", error);
      setErrorMessage("Ошибка при загрузке данных о книге.");
      setSelectedBook(null);
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(0);
    setSelectedBook(null);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);
      setSelectedBook(null);
    }
  };

  const handleBookClick = (id) => {
    fetchBookDetails(id);
  };

  const handleDownload = async (bookId) => {
    const response = await fetch(`/api/files/${bookId}`);
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${bookId}.pdf`; // Укажите имя файла
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      setErrorMessage("Ошибка при скачивании файла или книга не найдена.");
    }
  };

  const handleCloseModal = () => {
    setErrorMessage("");
  };

  return (
      <div className={s.library_container}>
        <h1>Библиотека</h1>
        <input
            type="text"
            placeholder="Поиск по названию..."
            className={s.search_input}
            value={searchQuery}
            onChange={handleSearch}
        />
        {roles.includes("ROLE_ADMIN") && (
            <button
                className={s.admin_button}
                onClick={() => navigate("/admin/books")}
            >
              Администрировать книги
            </button>
        )}
        {loading ? (
            <div className={s.loading}>Загрузка...</div>
        ) : selectedBook ? (
            <div className={s.book_details}>
              <button
                  className={s.back_button}
                  onClick={() => setSelectedBook(null)}
              >
                ← Назад к списку
              </button>
              <div className={s.details_card}>
                <h2>{selectedBook.name1}</h2>
                <p>
                  <strong>Автор:</strong> {selectedBook.author || "Не указано"}
                </p>
                {selectedBook.year && <p>
                  <strong>Год:</strong> {selectedBook.year}</p>}
                {selectedBook.annot && (
                    <p><strong>Аннотация:</strong> {selectedBook.annot}</p>
                )}
                {selectedBook.izdatel && (
                    <p><strong>Издатель:</strong> {selectedBook.izdatel}</p>
                )}
                {selectedBook.pages && <p>
                  <strong>Страниц:</strong> {selectedBook.pages}</p>}
                {selectedBook.pereplet && (
                    <p><strong>Переплет:</strong> {selectedBook.pereplet}</p>
                )}
                {selectedBook.tiraj && <p>
                  <strong>Тираж:</strong> {selectedBook.tiraj}</p>}
                {selectedBook.format && <p>
                  <strong>Формат:</strong> {selectedBook.format}</p>}
                <button
                    className={s.download_button}
                    onClick={() => handleDownload(selectedBook.id)}
                >
                  Скачать
                </button>
              </div>
            </div>
        ) : books.length > 0 ? (
            <div className={s.book_list}>
              {books.map((book) => (
                  <div
                      key={book.id}
                      className={`${s.book_item} ${s.clickable}`}
                      onClick={() => handleBookClick(book.id)}
                  >
                    <h3>{book.name1}</h3>
                    <p>{book.author}</p>
                    <small>{book.year}</small>
                    <button
                        className={s.download_button}
                        onClick={(e) => {
                          e.stopPropagation(); // Предотвращаем вызов handleBookClick
                          handleDownload(book.id);
                        }}
                    >
                      Скачать
                    </button>
                  </div>
              ))}
            </div>
        ) : (
            <div className={s.no_books}>Книги не найдены</div>
        )}
        {!selectedBook && (
            <div className={s.pagination}>
              <button
                  className={s.page_button}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 0}
              >
                &laquo; Назад
              </button>
              <span>
                Страница {currentPage + 1} из {totalPages > 0 ? totalPages : 1}
              </span>
              <button
                  className={s.page_button}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage + 1 >= totalPages || totalPages === 0}
              >
                Вперед &raquo;
              </button>
            </div>
        )}

        {/* Модальное окно для ошибок */}
        {errorMessage && (
            <div className={s.modal}>
              <div className={s.modal_content}>
                <h2>Ошибка</h2>
                <p>{errorMessage}</p>
                <button onClick={handleCloseModal} className={s.close_button}>
                  Закрыть
                </button>
              </div>
            </div>
        )}
      </div>
  );
};

export default Library;