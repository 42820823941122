import React, { useState } from "react";
import { useForm } from "react-hook-form";
import s from "./RegistrationForm.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PioneersLogo from "../../Pioneers_pin.svg";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Иконки для показа пароля
import { useDispatch } from "react-redux";
import { setUser, setError } from '../../redux/authSlice';

const RegistrationForm = () => {
  const [visible, setVisible] = useState(false);
  const [match, checkMatch] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (data.password !== data.passwordRepeat) {
      checkMatch(false);
    } else {
      checkMatch(true);
      delete data.passwordRepeat;

      try {
        const rawResponse = await fetch("/api/users/register", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (rawResponse.ok) {
          // Выполняем вход после успешной регистрации
          const loginResponse = await fetch('/api/perform_login', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: data.email, password: data.password }),
            credentials: 'include',
          });

          if (!loginResponse.ok) {
            const errorContent = await loginResponse.json();
            dispatch(setError(errorContent.message || 'Ошибка входа'));
            return;
          }

          const userData = await loginResponse.json();
          dispatch(setUser({
            user: userData.user,
            roles: userData.roles || [],
            permissions: userData.permissions || [],
          }));

          navigate("/registrationSuccess"); // Переход на страницу успешной регистрации
        } else if (rawResponse.status === 409) {
          const error = await rawResponse.text();
          alert(error);
        } else {
          alert("Произошла неизвестная ошибка. Попробуйте снова позже.");
        }
      } catch (error) {
        console.error("Ошибка при регистрации:", error);
        alert("Произошла ошибка. Попробуйте еще раз.");
      }
    }
  };

  const toggleVisibility = () => setVisible(!visible); // Логика для показа/скрытия пароля

  return (
      <>
        <h1 className={s.registrationHeader}>Регистрация на сайте</h1>
        <form className={s.registrationForm} onSubmit={handleSubmit(onSubmit)}>
          {/* Поле для электронной почты */}
          <div className="form-floating mb-3">
            <input
                type="email"
                className="form-control"
                id="email"
                placeholder="name@example.ru"
                {...register("email", { required: true })}
            />
            <label htmlFor="email">E-mail</label>
          </div>

          {/* Поля для пароля */}
          <div className={s.registrationField}>
            <div className={s.descriptionArea}>
              <p className={s.descriptionHeader}>Придумайте пароль</p>
              <p className={s.description}>
                Условия: латинские символы, минимум одна заглавная и одна
                маленькая буквы, одна цифра. Минимальная длина — 8 символов.
              </p>
            </div>
            <div className={s.inputArea}>
              <div className="passwordField">
                <input
                    type={visible ? "text" : "password"}
                    className={`form-control passwordInput ${match ? "" : "is-invalid"}`}
                    id="password"
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    placeholder="Пароль"
                    {...register("password", { required: true })}
                />
                <div className="showPasswordIcon" onClick={toggleVisibility}>
                  {visible ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>

              <div className="passwordField">
                <input
                    type={visible ? "text" : "password"}
                    className={`form-control passwordInput ${match ? "" : "is-invalid"}`}
                    id="passwordRepeat"
                    placeholder="Повторите пароль"
                    {...register("passwordRepeat", { required: true })}
                />
                <div className="showPasswordIcon" onClick={toggleVisibility}>
                  {visible ? <FaEyeSlash /> : <FaEye />}
                </div>
                {!match && (
                    <div className="invalid-feedback">Пароли не совпадают.</div>
                )}
              </div>
            </div>
          </div>

          <input type="submit" className="btn btn-primary" />
        </form>
      </>
  );
};

export default RegistrationForm;