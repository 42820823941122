import React, { useState } from "react";
import { useForm } from "react-hook-form";
import s from "./RegistrationForm.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PioneersLogo from "../../Pioneers_pin.svg";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Иконки для показа пароля

const RegistrationForm = () => {
  const [visible, setVisible] = useState(false);
  const [match, checkMatch] = useState(true);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data.password !== data.passwordRepeat) {
      checkMatch(false);
    } else {
      checkMatch(true);
      delete data.passwordRepeat;
      (async () => {
        const rawResponse = await fetch("/api/users/register", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        if (rawResponse.ok) {
          navigate("/registrationSuccess");
        } else if (rawResponse.status === 409) {
          const error = await rawResponse.text();
          alert(error);
        } else {
          alert("Произошла неизвестная ошибка. Попробуйте снова позже.");
        }
      })();
    }
  };

  const toggleVisibility = () => setVisible(!visible); // Логика для показа/скрытия пароля

  return (
    <>
      <h1 className={s.registrationHeader}>Регистрация на сайте</h1>
      <form className={s.registrationForm} onSubmit={handleSubmit(onSubmit)}>
        {/* ФИО */}
        <div className={s.registrationField}>
          <div className={s.descriptionArea}>
            <img src={PioneersLogo} width="80rem" />
            <p className={s.descriptionHeader}>Введите Ф.И.О.</p>
            <p className={s.description}>
              Проверьте правильность заполнения полей. Эти данные будут
              использованы в документах организации, например, при вступлении
            </p>
          </div>
          <div className={s.inputArea}>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="Имя"
                {...register("firstName", { required: true })}
              />
              <label htmlFor="firstName">Имя</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Фамилия"
                {...register("lastName", { required: true })}
              />
              <label htmlFor="lastName">Фамилия</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="patronymic"
                placeholder="Отчество"
                {...register("patronymic", { required: true })}
              />
              <label htmlFor="patronymic">Отчество</label>
            </div>
          </div>
        </div>

        {/* Общие сведения */}
        <div className={s.registrationField}>
          <div className={s.descriptionArea}>
            <p className={s.descriptionHeader}>Общие сведения</p>
            <p className={s.description}>
              Укажите ваши дату рождения, пол, место рождения и национальность
            </p>
          </div>
          <div className={s.inputArea}>
            <div className="form-floating mb-3">
              <select
                className="form-select"
                id="gender"
                aria-label="Пол"
                {...register("gender", { required: true })}
              >
                <option value="" disabled selected></option>
                <option value="MALE">Мужской</option>
                <option value="FEMALE">Женский</option>
              </select>
              <label htmlFor="gender">Пол</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="date"
                className="form-control"
                id="birthDate"
                {...register("birthDate", { required: true })}
              />
              <label htmlFor="birthDate">Дата рождения</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="birthPlace"
                placeholder="Место рождения"
                {...register("birthPlace", { required: true })}
              />
              <label htmlFor="birthPlace">Место рождения</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="nationality"
                placeholder="Национальность"
                {...register("nationality", { required: true })}
              />
              <label htmlFor="nationality">Национальность</label>
            </div>
          </div>
        </div>

        {/* Контактные данные */}
        <div className={s.registrationField}>
          <div className={s.descriptionArea}>
            <p className={s.descriptionHeader}>Контактные данные</p>
            <p className={s.description}>
              Укажите номер вашего мобильного телефона, адрес электронной почты,
              а также ссылки на аккаунты в социальных сетях.
            </p>
          </div>
          <div className={s.inputArea}>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                placeholder="Номер телефона"
                {...register("phoneNumber", { required: true })}
              />
              <label htmlFor="phoneNumber">Номер телефона</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="socialMedia"
                placeholder="Соцсети"
                {...register("socialMedia", { required: true })}
              />
              <label htmlFor="socialMedia">Соцсети</label>
            </div>
            <div className="form-floating">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="name@example.ru"
                {...register("email", { required: true })}
              />
              <label htmlFor="email">E-mail</label>
            </div>
          </div>
        </div>

        {/* Пароль */}
        <div className={s.registrationField}>
          <div className={s.descriptionArea}>
            <p className={s.descriptionHeader}>Придумайте пароль</p>
            <p className={s.description}>
              Условия: латинские символы, минимум одна заглавная и одна
              маленькая буквы, одна цифра. Минимальная длина — 8 символов.
            </p>
          </div>
          <div className={s.inputArea}>
            <div className="passwordField">
              <input
                type={visible ? "text" : "password"}
                className={`form-control passwordInput ${
                  match ? "" : "is-invalid"
                }`}
                id="password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                placeholder="Пароль"
                {...register("password", {
                  required: true,
                })}
              />
              <div className="showPasswordIcon" onClick={toggleVisibility}>
                {visible ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>

            <div className="passwordField">
              <input
                type={visible ? "text" : "password"}
                className={`form-control passwordInput ${
                  match ? "" : "is-invalid"
                }`}
                id="passwordRepeat"
                placeholder="Повторите пароль"
                {...register("passwordRepeat", { required: true })}
              />
              <div className="showPasswordIcon" onClick={toggleVisibility}>
                {visible ? <FaEyeSlash /> : <FaEye />}
              </div>
              {!match && (
                <div className="invalid-feedback">Пароли не совпадают.</div>
              )}
            </div>
          </div>
        </div>

        <input type="submit" />
      </form>
    </>
  );
};

export default RegistrationForm;
