import React from "react";
const Event = (props) => {
  return (
    <div>
      <p>
        {props.eventName} {props.description}
      </p>
    </div>
  );
};
export default Event;
