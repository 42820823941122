// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminPage_menu_div__g0lMv{

    background-color: rgba(225, 225, 225, 0.2);  
    text-align: center;
    padding: 1rem;
    width: 100%;
    display:block;
    
    
  }
  .AdminPage_item__5oKjY{
    text-decoration: none;
    margin: 2rem;
    color: rgb(151,1,14);
    font-family:  "Ysabeau SC";
  }
  .AdminPage_active__XZtAh{
    color: grey;
    padding-left: 1.5rem;
    text-decoration:none;
    font-weight:bold;
  }`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/AdminPage.module.css"],"names":[],"mappings":"AAAA;;IAEI,0CAA0C;IAC1C,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,aAAa;;;EAGf;EACA;IACE,qBAAqB;IACrB,YAAY;IACZ,oBAAoB;IACpB,0BAA0B;EAC5B;EACA;IACE,WAAW;IACX,oBAAoB;IACpB,oBAAoB;IACpB,gBAAgB;EAClB","sourcesContent":[".menu_div{\r\n\r\n    background-color: rgba(225, 225, 225, 0.2);  \r\n    text-align: center;\r\n    padding: 1rem;\r\n    width: 100%;\r\n    display:block;\r\n    \r\n    \r\n  }\r\n  .item{\r\n    text-decoration: none;\r\n    margin: 2rem;\r\n    color: rgb(151,1,14);\r\n    font-family:  \"Ysabeau SC\";\r\n  }\r\n  .active{\r\n    color: grey;\r\n    padding-left: 1.5rem;\r\n    text-decoration:none;\r\n    font-weight:bold;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu_div": `AdminPage_menu_div__g0lMv`,
	"item": `AdminPage_item__5oKjY`,
	"active": `AdminPage_active__XZtAh`
};
export default ___CSS_LOADER_EXPORT___;
