import React from "react";
import { useForm } from "react-hook-form";
import AdminPage from "./AdminPage";
const PrintDocs = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    let link = "";
    if (data.docType === "questionnaire")
      link = "http://localhost:80/api/forms/questionnaire";
    else link = "http://localhost:80/api/forms/application";
    (async () => {
      const response = await fetch(link, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(data.pioneerId),
      });
      const doc = await response.blob();
      function downloadFile2(name = `${data.pioneerId}_${data.docType}.docx`) {
        const href = URL.createObjectURL(doc);
        const a = Object.assign(document.createElement("a"), {
          href,
          style: "display:none",
          download: name,
        });
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(href);
        a.remove();
      }
      downloadFile2();
    })();
  };
  return (
    <>
      <AdminPage />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="form-floating mb-3">
          <select
            class="form-select"
            id="docType"
            aria-label="Тип документа"
            {...register("docType", { required: true })}
          >
            <option value="" disabled selected></option>
            <option value="questionnaire">Анкета</option>
            <option value="application">Заявление</option>
          </select>
          <label for="gender">Тип документа</label>
        </div>
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="pioneerId"
            placeholder="Pioneer ID"
            {...register("pioneerId", { required: true })}
          />
        </div>
        <label for="pioneerId">Pioneer ID</label>
        <input type="submit" />
      </form>
    </>
  );
};
export default PrintDocs;
