import React, { useState } from "react";
import { useForm } from "react-hook-form";
const AddUserEvent = (props) => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    data.userId = props.userId;
    (async () => {
      const rawResponse = await fetch("http://localhost:8080/api/user-events", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const content = await rawResponse.json();
      props.AddUserEvent(content);
    })();
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            list="userEventNames"
            id="userEventName"
            {...register("userEventName", { required: true })}
          />
          <datalist id="userEventNames">
            {props.events.map((item) => (
              <option value={item.eventId}>{item.eventName}</option>
            ))}
          </datalist>
          <label htmlFor="userEventName">Название события</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="date"
            className="form-control"
            id="date"
            {...register("date", { required: true })}
          />
          <label htmlFor="date">Дата события</label>
        </div>
        <input type="submit" value="Добавить событие" />
      </form>
    </>
  );
};
export default AddUserEvent;
