import React, { useEffect, useState } from "react";
import "./ProfilePage.css";

const ProfilePage = () => {
    const [user, setUser] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");

    // Загрузка данных пользователя при монтировании компонента
    useEffect(() => {
        const fetchUser = async () => {
            try {
                setLoading(true);
                const response = await fetch(`/api/users/profile`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    throw new Error("Не удалось загрузить данные пользователя");
                }
                const data = await response.json();
                setUser(data);
                setFormData(data);
                console.log("Данные пользователя загружены:", data); // Логируем данные
            } catch (error) {
                console.error("Ошибка при загрузке данных пользователя:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUser();
    }, []);

    // Обработчик изменения полей формы
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Обработчик отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("/api/users/profile", {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (!response.ok) {
                throw new Error("Не удалось обновить данные пользователя");
            }
            // Обновляем данные пользователя после успешного редактирования
            const updatedUser = await response.json();
            setUser(updatedUser);
            setFormData(updatedUser);
            setIsEditing(false);
            alert("Профиль успешно обновлен!");
        } catch (error) {
            console.error("Ошибка при обновлении данных пользователя:", error);
            alert("Произошла ошибка при обновлении профиля.");
        }
    };

    // Обработчик вступления в организацию
    const handleJoinOrganization = async () => {
        try {
            const response = await fetch("/api/users/join-organization", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                setMessage(errorMessage);
                return;
            }

            // Обновляем состояние пользователя, добавляя роль "ROLE_WAITING_MEMBER"
            setUser((prevUser) => ({
                ...prevUser,
                roles: [...prevUser.roles, { name: "ROLE_WAITING_MEMBER" }],
            }));

            setMessage("Заявка на вступление в организацию подана.");
        } catch (error) {
            console.error("Ошибка при отправке заявки на вступление:", error);
            setMessage("Произошла ошибка при отправке заявки.");
        }
    };

    // Проверка, заполнены ли все обязательные поля
    const isFormComplete = () => {
        const requiredFields = [
            "firstName",
            "lastName",
            "patronymic",
            "gender",
            "birthDate",
            "birthPlace",
            "nationality",
            "phoneNumber",
            "socialMedia",
        ];
        const isComplete = requiredFields.every((key) => formData[key]);
        console.log("Проверка заполненности формы:", isComplete); // Логируем результат
        return isComplete;
    };

    // Проверка, что у пользователя только одна роль ROLE_USER
    const isUserRoleOnly = () => {
        const isOnlyUserRole = user?.roles?.length === 1 && user.roles[0].name === "ROLE_USER";
        console.log("Проверка ролей пользователя:", user?.roles, "Результат:", isOnlyUserRole); // Логируем роли
        return isOnlyUserRole;
    };

    if (loading) {
        return <div className="loader">Загрузка...</div>;
    }

    return (
        <div className="profile-page">
            <h1>Личный кабинет</h1>
            {message && <p className="error-message">{message}</p>}
            {isEditing ? (
                <form onSubmit={handleSubmit} className="profile-form">
                    <div className="form-group">
                        <label>Имя:</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName || ""}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Фамилия:</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName || ""}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Отчество:</label>
                        <input
                            type="text"
                            name="patronymic"
                            value={formData.patronymic || ""}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Пол:</label>
                        <select
                            name="gender"
                            value={formData.gender || ""}
                            onChange={handleChange}
                        >
                            <option value="">Выберите пол</option>
                            <option value="MALE">Мужской</option>
                            <option value="FEMALE">Женский</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Дата рождения:</label>
                        <input
                            type="date"
                            name="birthDate"
                            value={formData.birthDate || ""}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Место рождения:</label>
                        <input
                            type="text"
                            name="birthPlace"
                            value={formData.birthPlace || ""}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Национальность:</label>
                        <input
                            type="text"
                            name="nationality"
                            value={formData.nationality || ""}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Телефон:</label>
                        <input
                            type="text"
                            name="phoneNumber"
                            value={formData.phoneNumber || ""}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Социальные сети:</label>
                        <input
                            type="text"
                            name="socialMedia"
                            value={formData.socialMedia || ""}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Сохранить
                    </button>
                    <button
                        type="button"
                        onClick={() => setIsEditing(false)}
                        className="btn btn-secondary"
                    >
                        Отмена
                    </button>
                </form>
            ) : (
                <div className="profile-info">
                    <p>Email: {user?.email || "Нет данных"}</p>
                    <p>Имя: {user?.firstName || "Нет данных"}</p>
                    <p>Фамилия: {user?.lastName || "Нет данных"}</p>
                    <p>Отчество: {user?.patronymic || "Нет данных"}</p>
                    <p>Пол: {user?.gender || "Нет данных"}</p>
                    <p>Дата рождения: {user?.birthDate || "Нет данных"}</p>
                    <p>Место рождения: {user?.birthPlace || "Нет данных"}</p>
                    <p>Национальность: {user?.nationality || "Нет данных"}</p>
                    <p>Телефон: {user?.phoneNumber || "Нет данных"}</p>
                    <p>Социальные сети: {user?.socialMedia || "Нет данных"}</p>
                    <button
                        onClick={() => setIsEditing(true)}
                        className="btn btn-edit"
                    >
                        Редактировать
                    </button>
                    {user?.roles.some(role => role.name === "ROLE_WAITING_MEMBER") ? (
                        <p>Ожидает одобрения вступления в организацию.</p>
                    ) : (
                        isUserRoleOnly() && (
                            <button
                                onClick={handleJoinOrganization}
                                className="btn btn-primary"
                                disabled={!isFormComplete()}
                            >
                                Вступить в организацию
                            </button>
                        )
                    )}
                </div>
            )}
            <div className="progress-info">
                <p>Заполните форму, чтобы подать заявку на вступление в организацию.</p>
            </div>
            <div className="progress-bar">
                <div
                    className="progress"
                    style={{
                        width: `${(Object.values(formData).filter(Boolean).length / Object.keys(formData).length) * 100}%`,
                    }}
                />
            </div>
        </div>
    );
};

export default ProfilePage;