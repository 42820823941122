import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "./Header/Header";
import MissionSection from "./MissionSection/MissionSection";
import NewsPreviewContainer from "./NewsPreview/NewsPreviewContainer";
import FooterGallery from "./FooterGallery/footerGallery";
import styles from "./MainPage.module.scss";
import { useSelector } from "react-redux"; // Импортируем useSelector для доступа к состоянию Redux

const MainPage = () => {
  const [atBottom, setAtBottom] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Получаем состояние аутентификации из Redux

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight =
          window.innerHeight || document.documentElement.clientHeight;

      // Проверяем, находимся ли мы близко к нижней части страницы
      setAtBottom(scrollTop + clientHeight >= scrollHeight - 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleJoinClick = () => {
    if (isAuthenticated) {
      // Если пользователь вошел, перенаправляем в личный кабинет
      alert("Перед тем, как подать заявление, убедитесь, что все поля заполнены верно.");
      window.location.href = "/profile"; // Замените на ваш путь к личному кабинету
    } else {
      // Если пользователь не вошел, перенаправляем на страницу логина
      alert("Чтобы подать заявление о вступлении, нужно войти на сайт и заполнить информацию в личном кабинете.");
      window.location.href = "/login"; // Замените на ваш путь к странице логина
    }
  };

  return (
      <>
        <Helmet>
          <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>
        <Header />
        <MissionSection />
        <NewsPreviewContainer />
        <FooterGallery />
        <button
            className={`${styles.joinButton} ${atBottom ? styles.moveUp : ""}`}
            onClick={handleJoinClick}
        >
          Вступить в организацию
        </button>
      </>
  );
};

export default MainPage;