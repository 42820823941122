import React from "react";
const UserEvent = (props) => {
  return (
    <div>
      {props.eventName} {props.eventDate}
      {/* <button
        onClick={() => {
          props.delete(props.id);
        }}
      >
        Удалить
      </button> */}
    </div>
  );
};
export default UserEvent;
